exports.components = {
  "component---src-components-blog-blog-post-layout-js": () => import("./../../../src/components/Blog/BlogPostLayout.js" /* webpackChunkName: "component---src-components-blog-blog-post-layout-js" */),
  "component---src-components-blog-categories-archive-js": () => import("./../../../src/components/Blog/CategoriesArchive.js" /* webpackChunkName: "component---src-components-blog-categories-archive-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-contentful-aviso-legal-slug-js": () => import("./../../../src/pages/aviso-legal/{contentfulAvisoLegal.slug}.js" /* webpackChunkName: "component---src-pages-aviso-legal-contentful-aviso-legal-slug-js" */),
  "component---src-pages-aviso-legal-index-js": () => import("./../../../src/pages/aviso-legal/index.js" /* webpackChunkName: "component---src-pages-aviso-legal-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-feedback-newsletter-js": () => import("./../../../src/pages/feedback-newsletter.js" /* webpackChunkName: "component---src-pages-feedback-newsletter-js" */),
  "component---src-pages-gracias-contacto-js": () => import("./../../../src/pages/gracias-contacto.js" /* webpackChunkName: "component---src-pages-gracias-contacto-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-gracias-tools-js": () => import("./../../../src/pages/gracias-tools.js" /* webpackChunkName: "component---src-pages-gracias-tools-js" */),
  "component---src-pages-herramientas-calculadora-notacion-cientifica-js": () => import("./../../../src/pages/herramientas/calculadora-notacion-cientifica.js" /* webpackChunkName: "component---src-pages-herramientas-calculadora-notacion-cientifica-js" */),
  "component---src-pages-herramientas-calculadora-trafico-js": () => import("./../../../src/pages/herramientas/calculadora-trafico.js" /* webpackChunkName: "component---src-pages-herramientas-calculadora-trafico-js" */),
  "component---src-pages-herramientas-contentful-tools-slug-js": () => import("./../../../src/pages/herramientas/{contentfulTools.slug}.js" /* webpackChunkName: "component---src-pages-herramientas-contentful-tools-slug-js" */),
  "component---src-pages-herramientas-index-js": () => import("./../../../src/pages/herramientas/index.js" /* webpackChunkName: "component---src-pages-herramientas-index-js" */),
  "component---src-pages-herramientas-muestreo-aleatorio-simple-js": () => import("./../../../src/pages/herramientas/muestreo-aleatorio-simple.js" /* webpackChunkName: "component---src-pages-herramientas-muestreo-aleatorio-simple-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insumos-js": () => import("./../../../src/pages/insumos.js" /* webpackChunkName: "component---src-pages-insumos-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-nuevo-cliente-js": () => import("./../../../src/pages/nuevo-cliente.js" /* webpackChunkName: "component---src-pages-nuevo-cliente-js" */),
  "component---src-pages-servicios-bi-js": () => import("./../../../src/pages/servicios/bi.js" /* webpackChunkName: "component---src-pages-servicios-bi-js" */),
  "component---src-pages-servicios-gtm-js": () => import("./../../../src/pages/servicios/gtm.js" /* webpackChunkName: "component---src-pages-servicios-gtm-js" */),
  "component---src-pages-servicios-index-js": () => import("./../../../src/pages/servicios/index.js" /* webpackChunkName: "component---src-pages-servicios-index-js" */),
  "component---src-pages-servicios-investigacion-de-mercados-js": () => import("./../../../src/pages/servicios/investigacion-de-mercados.js" /* webpackChunkName: "component---src-pages-servicios-investigacion-de-mercados-js" */)
}

